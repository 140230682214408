import APIService from '@/services/APIService.js';

export default {
  upload(postData, onUploadProgress) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout: 60 * 60 * 1000,
      onUploadProgress
    };

    return APIService.apiCall().post('arquivos/upload', postData, config);
  },
  getByAnoMesGrupo(ano, mes, grupoId) {
      return APIService.apiCall().get(`arquivos/get-by-ano-mes-grupo/${ano}/${mes}/${grupoId}`);
  },
  download(id, onUploadProgress) {
    return APIService.apiCall().get(`arquivos/download/${id}`, {
      responseType: 'blob',
      timeout: 60 * 60 * 1000,
      onUploadProgress
    });
  },
  delete(id) {
    return APIService.apiCall().delete(`arquivos/delete/${id}`);  
  }
};
